.custom-date-range-picker .react-daterange-picker__range-divider {
  color: #000;
  font-weight: bold;
}

.custom-date-range-picker .react-calendar__tile--range {
  background: #e0f7fa;
  color: #00796b;
}

.custom-date-range-picker .react-calendar__tile--rangeStart,
.custom-date-range-picker .react-calendar__tile--rangeEnd {
  background: #00796b;
  color: #fff;
}
